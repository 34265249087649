import { PageHeader } from 'antd'
import { Content } from 'antd/lib/layout/layout'

const PageHeading = ({ title, subtitle, goBack, status }) => {
  // useState, routing

  return (
    <div>
      <Content style={{ backgroundColor: '#fff', marginTop: 64 }}>
        <PageHeader
          title={<span style={{ fontWeight: 500 }}>{title}</span>}
          ghost={false}
          subTitle={subtitle}
          onBack={status ? goBack : false}
          style={{
            maxWidth: 1480,
            margin: '0 auto',
            width: '100%',
            padding: '16px 40px',
          }}
        ></PageHeader>
      </Content>
    </div>
  )
}

export default PageHeading
