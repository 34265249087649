import * as actions from '@store/constants/process'

export const addNewStage = stage => {
  return {
    type: actions.ADD_STAGE,
    payload: stage
  }
}

export const addNewStep = (id, selectedFunc, expandedFunc) => {
  return {
    type: actions.ADD_STEP,
    payload: { id },
    selectedFunc,
    expandedFunc
  }
}

export const deleteTreeEl = (id, selectedFunc, expandedFunc) => {
  return {
    type: actions.REMOVE_TREE_EL,
    payload: { id },
    selectedFunc,
    expandedFunc
  }
}

export const setDragAndDrop = tree => {
  return {
    type: actions.DRAG_AND_DROP,
    payload: tree
  }
}

export const setProcessEdit = tree => {
  return {
    type: actions.PROCESS_EDIT,
    payload: tree
  }
}
