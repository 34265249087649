import { Link, useLocation } from 'react-router-dom'

import { Input, Row, Col, Button } from 'antd'

import { useTranslation } from 'react-i18next'

const { Search } = Input

const SearchBlock = ({ onSearch }) => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <>
      <Row justify="space-between" align="middle" className="container">
        <Col flex="400px">
          <Search
            placeholder={t('common.search')}
            onSearch={onSearch}
            onChange={e => onSearch(e.target.value)}
            allowClear
            style={{ maxWidth: 400 }}
          />
        </Col>
        <Col>
          <Button type="primary">
            <Link to={`${location.pathname}/add`}>{t('common.add')}</Link>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SearchBlock
