import * as actions from '@store/constants/process'

const treeData = [{
  key: '1',
  title: 'Новый процесс',
  canEditClientCard: true,
  isMain: true,
  name: 'Новый процесс',
  showClientCard: true,
  userIds: [],
  children: [
    {
      key: '1-1',
      title: 'Новый этап',
      cancelReasons: [],
      newCancelReasons: [],
      children: [
        {
          key: '1-1-1',
          title: 'Новый шаг',
          description: '',
          required: true,
          module: '',
          settings: {}
        }
      ]
    }
  ]
}]

export const tree = (state = treeData, action) => {
  switch (action.type) {
    case actions.ADD_STAGE:
      state.forEach((item, index) => {
        state[index].children.push({
          key: item.key + '-' + (item.children.length + 1).toString(),
          title: 'Новый этап',
          cancelReasons: [],
          newCancelReasons: [],
          children: []
        })
      })

      return [...state]

    case actions.ADD_STEP:
      state[0].children.forEach((item, index) => {
        if (
          item.key === action.payload.id ||
                    state[0].children[index].children.some(
                      x => x.key === action.payload.id
                    )
        ) {
          state[0].children[index].children.push({
            key: item.key + '-' + (item.children.length + 1).toString(),
            title: 'Новый шаг',
            description: '',
            required: true,
            module: '',
            settings: {}
          })

          action.selectedFunc(
            state[0].children[index].children.length && [
              state[0].children[index].children[
                            state[0].children[index].children.length - 1
              ].key
            ]
          )

          if (state[0].children.length !== 1) {
            action.expandedFunc(
              state[0].children[index].children.length && [
                state[0].children[index].children[
                                state[0].children[index].children.length - 1
                ].key
              ]
            )
          }
        }
      })

      return [...state]

    case actions.REMOVE_TREE_EL:
      state[0].children.forEach((item, index) => {
        if (item.key === action.payload.id) {
          state[0].children = state[0].children.filter(item => {
            if (!action.payload.id.includes(item.key)) {
              return true
            } else {return false}
          })
        } else if (
          item.key !== action.payload.id &&
                    state[0].children[index] &&
                    state[0].children[index].children.some(x => action.payload.id.includes(x.key)
                    )
        ) {
          state[0].children[index].children = state[0].children[
                        index
          ].children.filter(innerItem => {
            if (!action.payload.id.includes(innerItem.key)) {
              return true
            } else {return false}
          })
        }
      })

      state[0].children.forEach((item, index) => {
        item.key = '1-' + (index + 1).toString()

        state[0].children[index].children.forEach((innerItem, index) => {
          innerItem.key = item.key + '-' + (index + 1).toString()
        })

        action.selectedFunc(
          state[0].children[index].children.length && [
            state[0].children[index].children[
                        state[0].children[index].children.length - 1
            ].key
          ]
        )
      })
      return [...state]

      // case actions.DRAG_AND_DROP:
      //     return [...state]

    case actions.PROCESS_EDIT:
      return state = action.payload


    default:
      return [...state]
  }
}