import { Collapse } from 'antd'
const { Panel } = Collapse

const Characteristics = ({ ...props }) => {
  const data = props.optionsData?.carOptions || null

  return (
    <>
      <Collapse bordered={false} style={{ background: 'transparent' }}>
        {data?.map((item) => {
          return (
            <Panel header={item.name} key={item.id} className='custom-collapse'>
              <div
                style={{
                  maxWidth: 600,
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  padding: '0 12px',
                }}
              >
                {item.options?.map((innerItem, index) => {
                  return (
                    <span
                      style={{ wordBreak: 'break-all' }}
                      key={index}
                    >
                      {innerItem.description}
                    </span>
                  )
                })}
              </div>
            </Panel>
          )
        })}
      </Collapse>
    </>
  )
}

export default Characteristics
