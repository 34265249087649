import { Modal } from 'antd'

const useModal = () => {
  const openModal = (obj) => {
    Modal.info({
      title: obj.title || '',
      content: obj.content,
      okText: obj.okText,
      width: 500,
      closable: 'true',
      onOk() {},
    })
  }
  return openModal
}

export default useModal
