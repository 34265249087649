import { LoadingOutlined } from '@ant-design/icons'
import Loader from '@img/loader.gif'

const Loading = ({ spinner }) => (
  <div className='loader-container'>
    <div className='loader'>
      {!spinner && <img src={Loader} alt='' className='loader-img' />}
      {spinner && (
        <LoadingOutlined style={{ color: '#0989ff', fontSize: 20 }} />
      )}
    </div>
  </div>
)

Loading.defaultProps = { spinner: false }

export default Loading
