import Header from '@components/common/Header'
import { Outlet } from 'react-router-dom'
import { Layout, Space } from 'antd'
const { Content } = Layout

const MainReportLayout = ({ notification }) => {
  const contextData = {
    cardEmptyStyle: {
      height: 616,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    notification: notification,
  }

  return (
    <>
      <Header />
      <Layout>
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 14px)', paddingTop: 64 }}>
          <Content>
            <Space
              direction="vertical"
              size={20}
              style={{ width: '100%', }}
            >
              <Outlet context={contextData} />
            </Space>
          </Content>
        </div>
      </Layout>
    </>
  )
}

export default MainReportLayout
