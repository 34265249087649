import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProtectedRoute from '../auth/ProtectedRoute'
import MainLayout from '@/layout/MainLayout'
import SettingsLayout from '@/layout/SettingsLayout'
import ProcessSettingsLayout from '@/layout/ProcessSettingsLayout'
import ReportLayout from '@/layout/ReportLayout'
import TasksLayout from '@/layout/TasksLayout'
import MainReportLayout from '@/layout/MainReportLayout'


// Home
const Home = React.lazy(() => import('@pages/Home/Home'))

// Staff
const Staff = React.lazy(() => import('@pages/Staff/Staff'))
const AddStaff = React.lazy(() =>import('@pages/Staff/AddStaff'))
const EditStaff = React.lazy(() => import('@pages/Staff/EditStaff'))

// Stock
const Stock = React.lazy(() =>  import('@pages/Stock/Stock'))
const AddStock = React.lazy(() => import('@pages/Stock/AddStock'))
const AddParts = React.lazy(() =>import('@pages/Stock/AddParts'))
const SetStock = React.lazy(() => import('@pages/Stock/SetStock'))

// Form
const Form = React.lazy(() => import('@pages/Form/Form'))
const FormBlock = React.lazy(() =>import('@pages/Form/FormBlock'))
const FormEdit = React.lazy(() =>import('@pages/Form/FormEdit'))
const FormSelect = React.lazy(() => import('@pages/Form/FormSelect'))

//Services
const Services = React.lazy(() => import('@pages/Services/Services'))
const AddServices = React.lazy(() => import('@pages/Services/AddServices'))
const EditServices = React.lazy(() =>import('@pages/Services/EditServices'))
const ChooseSpecialist = React.lazy(() =>import('@pages/Services/ChooseSpecialist'),
)

// Process
const Process = React.lazy(() => import('@pages/Process/Process'))
/*const AddProcess = React.lazy(() => import('@pages/Process/AddProcess'))
const ProcessTemplate = React.lazy(() =>
  import('@pages/Process/ProcessTemplate')
)
const ProcessPreview = React.lazy(() => import('@pages/Process/ProcessPreview'))*/

// Process - Settings
const ProcessSettings = React.lazy(() => import('@pages/Process/ProcessSettings'),
)

const ProcessSettingsEdit = React.lazy(() => import('@pages/Process/ProcessSettingsEdit'),
)
const SelectProcess = React.lazy(() => import('@pages/Process/SelectProcess'))

// Clients
const Clients = React.lazy(() => import('@pages/Clients/Clients'))
const ClientCard = React.lazy(() =>import('@pages/Clients/ClientCard'))
const ClientsSetting = React.lazy(() =>  import('@pages/Clients/ClientsSetting'))
const ClientAdd = React.lazy(() => import('@pages/Clients/ClientAdd'))

// Analytics
const Analytics = React.lazy(() => import('@pages/Analytics'))
const Report = React.lazy(() =>import('@pages/Analytics/Report'))

// Tasks
const Tasks = React.lazy(() =>import('@pages/Tasks'))
const NewReports = React.lazy(() =>import('@pages/Reports'))
const NewReport = React.lazy(() =>import('@pages/Reports/Report'))

// Error
const Error = React.lazy(() =>import('@pages/Error'))

const Login = React.lazy(() => import('@pages/Login'))

// global Func

const Index = ({ notification }) => {
  const { user } = useSelector((state) => state.app)
  const { token } = useSelector((state) => state.app)
  const { allModules } = useSelector((state) => state.app)

  const setPermissionsStatus = (id) => {
    let permissionsStatus = false

    user?.permissions.forEach((item) => {
      if (item.app_module && item.app_module.id === id) {
        permissionsStatus = true
      }
    })

    return permissionsStatus
  }

  let permissionsAllRoutes = []

  permissionsAllRoutes = allModules
    ?.map((item) => {
      if (setPermissionsStatus(item.id)) {
        switch (item.url) {
          case '/staff':
            return <Route key={2} path="staff" element={<Staff />} />
          case '/process':
            return <Route key={3} path="process" element={<Process />} />
          case '/form':
            return <Route key={4} path="form" element={<Form />} />
          case '/services':
            return <Route key={5} path="services" element={<Services />} />
          case '/stock':
            return <Route key={6} path="stock" element={<Stock />} />
          case '/clients':
            return <Route key={7} path="clients" element={<Clients />} />
          case '/analytics':
            return <Route key={8} path="analytics" element={<Analytics />} />
          case '/tasks':
            return <Route key={9} path="tasks" element={<Tasks />} />
          case '/reports':
            return <Route key={10} path="tasks" element={<NewReports />} />
        }
      } else {
        return <Route key="error" path="*" element={<Error />} />
      }
    })
    .filter((i) => i !== undefined)

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<MainLayout notification={notification} />}>
            <Route key={1} index element={<Home />} />
            {permissionsAllRoutes?.map((item) => {
              return item
            })}
          </Route>

          {permissionsAllRoutes?.some((x) => x.key === '2') ? (
              <Route
                path={'/staff/*'}
                element={<SettingsLayout notification={notification} />}
              >
                <Route path="add" element={<AddStaff />} />
                <Route path="edit/:id" element={<EditStaff />} />
                <Route path="select-process" element={<SelectProcess />} />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) =>  x.key === '4') ? (
              <Route
                path={'/form/*'}
                element={<SettingsLayout notification={notification} />}
              >
                <Route path="add" element={<FormBlock />} />
                <Route path="select" element={<FormSelect />} />
                <Route path="edit/:id" element={<FormEdit />} />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) => x.key === '5') ? (
              <Route
                path={'/services/*'}
                element={<SettingsLayout notification={notification} />}
              >
                <Route path="add" element={<AddServices />} />
                <Route path="add/specialist" element={<ChooseSpecialist />} />
                <Route path="edit/:id" element={<EditServices />} />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) => x.key === '3') ? (
              <Route
                path={'/process/*'}
                element={<ProcessSettingsLayout notification={notification} />}
              >
                <Route path="add" element={<ProcessSettings />} />
                <Route path="edit/:id" element={<ProcessSettingsEdit />} />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) =>  x.key === '6') ? (
              <Route path={'/stock/*'} element={<SettingsLayout />}>
                <Route path="add/:type" element={<AddStock key="add" />} />
                <Route
                  path="edit/:type/:carId"
                  element={<AddStock key="edit" />}
                />
                <Route path="set/:type" element={<SetStock />} />
                <Route path="add/parts" element={<AddParts />} />
                <Route
                  path="edit/parts/:id"
                  element={<AddParts key="editpart" />}
                />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) => x.key === '7') ? (
              <>
                <Route path={'/clients/*'} element={<SettingsLayout />}>
                  <Route path="card/:id" element={<ClientCard />} />
                  <Route
                    path="settings/add/:type"
                    element={<ClientAdd key="add" />}
                  />
                  <Route
                    path="settings/edit/:type/:groupId"
                    element={<ClientAdd key="edit" />}
                  />
                </Route>

                <Route path={'/clients/*'} element={<MainLayout />}>
                  <Route path="settings" element={<ClientsSetting />} />
                </Route>
              </>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) => x.key === '8') ? (
              <Route path={'/analytics/*'} element={<ReportLayout />}>
                <Route path="reports/:id" element={<Report />} />
              </Route>
            ) : (
              ''
            )}

          {permissionsAllRoutes?.some((x) =>  x.key === '9') ? (
              <Route path={'/tasks'} element={<TasksLayout />}>
                <Route path="/tasks" element={<Tasks />} />
              </Route>
            ) : (
              ''
            )}
          {permissionsAllRoutes?.some((x) =>  x.key === '10') ? (
              <Route path={'/reports'} element={<MainReportLayout />}>
                <Route path="/reports" element={<NewReports />} />
                <Route path="/reports/:id" element={<NewReport />} />
              </Route>
            ) : (
              ''
            )}
        </Route>
        {!token && (
          <>
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<Login step={4} />} />
          </>
        )}
      </Routes>
    </>
  )
}

export default Index
