import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Typography, Button, Row, Col, Space, Skeleton, Tabs, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { MainTab, DescTab, Options } from './tabs'
import { authorizationLayer } from '@store/actions/methods'
import { onCloseModal } from '@utils'

const { Header } = Layout
const { Title } = Typography

const SelectProcess = ({ id }) => {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.app.modals)
  const [productData, setProductData] = useState()
  const [optionsData, setOptionsData] = useState()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const fetchData = async() => {
      setLoading(true)
      await dispatch(
        authorizationLayer({
          url: `wh-cars/used_car_card/main/${id}`,
          method: 'get',
        })
      ).then((res) => {
        setProductData(res.data)
      })

      await dispatch(
        authorizationLayer({
          url: `wh-cars/used_car_card/options/${id}`,
          method: 'get',
        })
      ).then((res) => {
        setOptionsData(res.data)
      })
      setLoading(false)
    }
    fetchData();
  }, [dispatch, id])

  let tabsComponents = [
    {
      id: 1,
      content: <MainTab productData={productData} />,
      title: t('stock.tabs.main'),
    },
    optionsData?.carOptions.length
      ? {
        id: 2,
        content: <Options optionsData={optionsData} />,
        title: t('stock.tabs.options'),
      }
      : { id: 1002 },
    productData?.additional_option
      ? {
        id: 3,
        content: <DescTab desc={productData?.additional_option} />,
        title: t('stock.tabs.desc'),
      }
      : { id: 1003 },
  ].sort((a, b) => a.id - b.id)

  return (
    <div className='modal__popup' style={{ zIndex: 100000000 }}>
      <Layout style={{ minHeight: '100vh', }}>
        <Header>
          <Row justify='space-between' align='middle'>
            <Col>
              <Title level={4}>
                {t('stock.modal.title')}
              </Title>
            </Col>
            <Col>
              <Button onClick={() => onCloseModal(dispatch, modals, 'select-car')}>
                {t('stock.modal.btn.closed')}
              </Button>
            </Col>
          </Row>
        </Header>

        <div
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 64px)',
          }}
        >
          <Card bodyStyle={{ padding: '40px 80px 104px' }}>
            <Row justify='space-between' wrap={false}>
              {!loading ? (
                <Col flex='auto'>
                  <Space direction='vertical' size={8}>
                    <Title level={3}>
                      {productData?.carMark?.name} {productData?.carModel?.name}
                    </Title>
                  </Space>
                  <Tabs
                    defaultActiveKey='1'
                    style={{ marginTop: 28 }}
                    destroyInactiveTabPane={true}
                    items={tabsComponents.map((item) => ({
                      label: item.title,
                      key: item.id,
                      children: <div style={{ marginTop: 16 }}>{item.content}</div>
                    }))}
                  />
                </Col>
              ) : (
                <Space direction='vertical' size={41} style={{ width: '50%' }}>
                  <Skeleton active paragraph={{ rows: 1 }} />
                  <Space size={20}>
                    <Skeleton.Button
                      active
                      shape='round'
                      size='middle'
                      style={{ width: 100 }}
                    />
                    <Skeleton.Button
                      active
                      shape='round'
                      size='middle'
                      style={{ width: 100 }}
                    />
                    <Skeleton.Button
                      active
                      shape='round'
                      size='middle'
                      style={{ width: 100 }}
                    />
                    <Skeleton.Button
                      active
                      shape='round'
                      size='middle'
                      style={{ width: 100 }}
                    />
                  </Space>
                  <Skeleton active />
                </Space>
              )}
            </Row>
          </Card>
        </div>
      </Layout>
    </div>
  )
}

export default SelectProcess
