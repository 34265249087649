import { useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LOGOUT } from '@store/actions/methods'

import {
  Layout,
  Button,
  Drawer,
  Space,
  Row,
  Col,
  Dropdown,
  Menu,
  Divider,
  Avatar,
  Typography,
  Modal
} from 'antd'

import {
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
  ExportOutlined,
} from '@ant-design/icons'

import Logo from '@img/logo.svg'

import Routing from '@components/common/Routing'

import { useTranslation } from 'react-i18next'

const { Header } = Layout

const { Text } = Typography

const App = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)
  const { moduleCategories } = useSelector((state) => state.app)
  const { user } = useSelector((state) => state.app)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const beforeLogout = () => {
    Modal.confirm({
      title: t('common.header.logOut.modal.title'),
      cancelText: t('common.header.logOut.modal.no'),
      okText: t('common.header.logOut.modal.yes'),
      okType: 'danger',
      onOk() {
        dispatch(LOGOUT())
        navigate('/login')
      },
      maskClosable: true,
    })
  }

  const menu = (
    <Menu
      onClick={beforeLogout}
      items={[
        {
          label: t('common.header.logOut'),
          key: 'exit',
          icon: <ExportOutlined />,
        },
      ]}
    />
  )

  const userName = () => {
    let name = []
    if (user.last_name) {name.push(user.last_name)}
    if (user.name) {name.push(user.name)}

    if (name.length === 0) {name.push(user.email)}

    return name.join(' ')
  }

  if (!user) {return null}

  // Func

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Layout className="fixed-container">
      <Drawer
        title={
          <Link to="/" onClick={toggleCollapsed}>
            <img src={Logo} alt="Logo" style={{ height: 30 }} />
          </Link>
        }
        placement="left"
        closable={false}
        open={collapsed}
        width={678}
        onClose={toggleCollapsed}
        className="custom-overflow"
      >
        <Button
          type="default"
          onClick={toggleCollapsed}
          size="small"
          style={{
            border: 'none',
            position: 'absolute',
            right: 24,
            top: 23,
            color: 'rgba(0, 0, 0, 0.45)',
            padding: 0,
            height: 16,
          }}
        >
          <CloseOutlined className="small-icon" />
        </Button>
        {moduleCategories && (
          <Routing
            columnGrid={2}
            toggleCollapsed={toggleCollapsed}
            moduleCategories={moduleCategories}
            user_metadata={user}
          />
        )}
      </Drawer>
      <Layout>
        <Header>
          <Row justify="space-between" align="middle">
            <Col>
              <Space size={18} align="start">
                {location.pathname !== '/' ? (
                  <MenuOutlined
                    className="medium-icon"
                    style={{ marginTop: 22 }}
                    onClick={toggleCollapsed}
                  />
                ) : (
                  ''
                )}
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Space>
            </Col>
            <Col>
              <Space size={20}>
                {/* <Space size={22.5}>
                  <Button
                    type="default"
                    size="small"
                    style={btnIconStyle}
                    onClick={notification}
                  >
                    <MessageOutlined className="medium-icon" />
                  </Button>
                  <Button
                    type="default"
                    size="small"
                    style={btnIconStyle}
                    onClick={notification}
                  >
                    <BellOutlined className="medium-icon" />
                  </Button>
                  <Dropdown
                    overlay={menu}
                    // trigger={['click']}
                    placement="bottomLeft"
                    onClick={notification}
                  >
                    <Button
                      type="default"
                      size="medium"
                      style={{
                        border: 'none',
                        padding: 0
                      }}
                    >
                      Помощь
                      <DownOutlined className="medium-icon" />
                    </Button>
                  </Dropdown>
                </Space> */}
                <Divider type="vertical" />
                <Dropdown overlay={menu} placement="bottomLeft">
                  <Space size={13}>
                    <Avatar
                      icon={
                        <UserOutlined style={{ backgroundColor: '#0989FF' }} />
                      }
                      size={32}
                      style={{ backgroundColor: '#0989FF' }}
                    />
                    <Text>{userName()}</Text>
                  </Space>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
      </Layout>
    </Layout>
  )
}
export default App
