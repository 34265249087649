import  { useState, useCallback } from 'react'

import { Outlet, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import {
  authorizationLayer,
  POST_DATA,
  UPDATE_EDIT_DATA,
} from '@store/actions/methods'

import { Layout, Button, Row, Col, Typography, Space, notification, Modal } from 'antd'
import useModal from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import Immutable from 'immutable'

const { Header, Content } = Layout

const { Title } = Typography

const SettingsLayout = ({ ...props }) => {
  // useState
  const dispatch = useDispatch()
  const [currentTitle, setCurrentTitle] = useState('')
  const [goBackAdress, setGoBackAdress] = useState('/')
  const [urlToPost, setUrlToPost] = useState('')
  const [dataToPost, setDataToPost] = useState({})
  const [redirectUrlToPost, setRedirectUrlToPost] = useState('')
  const [disabledStatus, setDisabledStatus] = useState(true)
  const [forEditStatus, setForEditStatus] = useState(false)
  const [forProcessData, setForProcessData] = useState([])
  const navigate = useNavigate()
  const openCompletedModal = useModal()
  const currentData = useSelector((state) => state.app.currentData)

  const { t } = useTranslation()

  // Func
  const renameTitle = useCallback(
    (title) => {
      setCurrentTitle(title)
    },
    [setCurrentTitle]
  )

  const settingGoBackAdress = useCallback(
    (adress) => {
      setGoBackAdress(adress)
    },
    [setGoBackAdress]
  )

  const updateNotification = () => {
    notification.info({
      message: 'Поменяйте пароль через почту',
      duration: 3,
    })
  }

  // useEffect(() => {
  //   dispatch(SET_APP(['currentData'], null))
  // }, [])

  const saveSettings = (
    url = urlToPost,
    formData = dataToPost,
    redirectUrl = redirectUrlToPost
  ) => {
    if (forEditStatus) {
      dispatch(UPDATE_EDIT_DATA(url, formData)).then((res) => {
        if (res.data.email_changed) {
          updateNotification()
        }
        if (res.status === 200) {
          navigate(redirectUrl)
        }
        if (!res.data.complete && Object.prototype.hasOwnProperty.call(res.data,'complete')) {
          openCompletedModal({
            title: 'Изменение полей недоступно ',
            content: 'Невозможно менять поля во избежание потерь сохраненных данных клиентов',
            okText: 'Закрыть',
          })
        }

        if (url.includes('users')) {
          dispatch(
            authorizationLayer({
              url: 'processes/process-user/add-processes-to-user',
              method: 'POST',
              data: {
                userId: res.data.id,
                processIds: forProcessData,
              },
            })
          )
        }
      })
    } else {
      dispatch(POST_DATA(url, formData)).then((res) => {
        if (res.status === 201) {
          navigate(redirectUrl)
          if (typeof redirectUrl === 'function') {
            navigate(redirectUrl(res))
          } else {
            navigate(redirectUrl)
          }
        }

        if (url === 'users') {
          dispatch(
            authorizationLayer({
              url: 'processes/process-user/add-processes-to-user',
              method: 'POST',
              data: {
                userId: res.data.id,
                processIds: forProcessData,
              },
            })
          )
        }
      })
    }
  }

  const contextData = {
    renameTitleFunc: renameTitle,
    goBackFunc: settingGoBackAdress,
    notification: props.notification,
    urlUpdate: setUrlToPost,
    dataUpdate: setDataToPost,
    disabledStatusEdit: setDisabledStatus,
    redirectFuncUpdate: setRedirectUrlToPost,
    editFunc: setForEditStatus,
    setForProcessData: setForProcessData,
  }

  // console.log('currentData', currentData)
  // console.log('dataToPost', dataToPost)

  const beforeCancel = () => {
    if (!Immutable.fromJS(dataToPost).equals(Immutable.fromJS(currentData)))
    {Modal.confirm({
      title: t('common.header.modal.cancel.title'),
      content: t('common.header.modal.cancel.subtitle'),
      cancelText: t('common.header.modal.cancel.no'),
      okText: t('common.header.modal.cancel.yes'),
      okType: 'danger',
      onOk() {
        navigate(goBackAdress)
      },
    })}
    else {navigate(goBackAdress)}
  }

  return (
    <>
      <Header className='fixed-container'>
        <Row justify='space-between' align='middle'>
          <Col>
            <Title level={4} style={{ fontWeight: 500 }}>
              {currentTitle}
            </Title>
          </Col>
          <Col>
            <Space size={20}>
              <Button onClick={() => beforeCancel()}>
                {t('common.header.cancel')}
              </Button>
              <Button
                type='primary'
                disabled={disabledStatus}
                onClick={() => saveSettings()}
                shape='round'
              >
                {t('common.header.save')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Header>
      <Layout>
        <div
          style={{
            overflowY: 'auto',
            height: 'calc(100vh - 64px)',
            marginTop: 64,
          }}
        >
          <Content
            style={{
              padding: '0 40px',
              maxWidth: 1480,
              margin: '24px auto 0 ',
              width: '100%',
              paddingBottom: 94,
            }}
          >
            <Outlet context={contextData} />
          </Content>
        </div>
      </Layout>
    </>
  )
}

export default SettingsLayout
