import { notification } from 'antd'

import { useTranslation } from 'react-i18next'

const useNotification = status => {

  const { t } = useTranslation()
  const openNotification = () => {
    if (status) {
      notification.info({
        message: t('common.notification.message'),
        description: t('common.notification.description'),
        duration: 3
      })
    }
  }
  return openNotification
}

export default useNotification
